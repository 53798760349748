import { css } from '@emotion/react'
import { createFilterOptions, type FilterOptionsState } from '@mui/material'
import type { GroupData } from '@orus.eu/backend/src/views/activity-grouping-view'
import type { RcphProduct } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { SearchBar, spacing } from '@orus.eu/pharaoh'
import { ProductBadge } from '@orus.eu/pharaoh/src/components/features/backoffice-quote-editor/badges'
import { colorTokens } from '@orus.eu/pharaoh/src/foundation/color-tokens'
import type { Product } from '@orus.eu/product'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { memo, useMemo, useState, type FunctionComponent } from 'react'
import { trpcReact } from '../../client'
import type { CompleteGroupLoader } from '../../lib/group-mapping-util'
import { useApi } from '../../lib/use-api/use-api'

export type ActivityGroupSearchFieldProps = {
  operatingZone: OperatingZone
  groupLoader: CompleteGroupLoader
  onChange: (group: GroupData) => void
  hasMainActivity?: boolean
  product?: Product
  rcphProduct?: RcphProduct
  selectedActivityIds?: Set<string>
  disabled?: boolean
}

const defaultFilterOptions = createFilterOptions<GroupData>()
const filterOptions = (options: GroupData[], state: FilterOptionsState<GroupData>) => {
  return defaultFilterOptions(options, state).slice(0, 20)
}

export const ActivityGroupSearchField: FunctionComponent<ActivityGroupSearchFieldProps> = memo(
  function ActivityGroupSearchField({
    operatingZone,
    onChange,
    groupLoader,
    disabled,
    hasMainActivity,
    rcphProduct,
    product,
    selectedActivityIds,
  }) {
    const [inputValue, setInputValue] = useState('')

    const activitiesResponse = useApi(groupLoader.getGroups)
    const activityList = useMemo(
      () =>
        activitiesResponse.ready
          ? activitiesResponse.data.filter((group) => group.operatingZone === operatingZone)
          : emptyActivityList,
      [activitiesResponse.data, activitiesResponse.ready, operatingZone],
    )

    const { data: list } =
      trpcReact.selfOnboarding.getGroupsWithSelfOnboardingForProductIncludingSecondaryActivities.useQuery(
        {
          operatingZone,
          search: inputValue,
          product: product as Product, // api call is placed only if product is defined
          rcphProduct,
        },
        { enabled: !!product && hasMainActivity },
      )

    const secondaryActivities = (list ?? []).reduce((acc: GroupData[], group) => {
      if (acc.some((existingGroup) => existingGroup.id === group.mainActivityId)) return acc

      if (selectedActivityIds?.has(group.mainActivityId)) return acc

      acc.push({
        name: group.name,
        operatingZone,
        description: group.description,
        products: group.products,
        id: group.mainActivityId,
      })
      return acc
    }, [])

    const searchOptions = hasMainActivity ? secondaryActivities : activityList

    return (
      <SearchBar
        size="small"
        options={searchOptions}
        placeholder="Exemple : restaurant, psychologue, développeur..."
        freeSolo={false}
        value={null}
        onChange={(_event, group) => {
          setInputValue('')
          if (group) onChange(group)
        }}
        hasCustomEndAdornment={true}
        disabled={!activitiesResponse.ready || disabled}
        getOptionLabel={(group: GroupData) => group.name}
        filterOptions={filterOptions}
        inputValue={inputValue}
        onInputChange={(_event, newValue) => setInputValue(newValue)}
        blurOnSelect={true}
        clearInputValue={() => setInputValue('')}
        renderOption={(props, group, { inputValue }) => {
          const { key, ...restProps } = props
          const groupName = group.name
          const matches = match(groupName, inputValue, { insideWords: true })
          const parts = parse(groupName, matches)

          return (
            <li
              key={key}
              {...restProps}
              css={css`
                display: flex;
                gap: ${spacing[20]};
              `}
            >
              <div
                css={css`
                  flex-grow: 1;
                `}
              >
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? colorTokens['color-text-base-link'] : undefined,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
              {group.products.map((product) => (
                <ProductBadge key={product} product={product} />
              ))}
            </li>
          )
        }}
      />
    )
  },
)

const emptyActivityList: GroupData[] = []
